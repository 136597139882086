import React from "react";

function Dashboard() {
	return (
		<>
			<div id="dashboard" className="dashboard">
				<div className="container">
					<h1>Dashboard</h1>
				</div>
				<div className="row dashboard_items">
					<div className="col-lg dashboard_col">
						<div className="dashboard_item">
							<div className="dashboard_item_background" style={{ backgroundImage: "url(images/chart1.jpg)" }}></div>
						</div>
					</div>
					<div className="col-lg dashboard_col">
						<div className="dashboard_item">
							<div className="dashboard_item_background" style={{ backgroundImage: "url(images/chart1.jpg)" }}></div>
						</div>
					</div>
				</div>

				<div className="row dashboard_items">
					<div className="col-lg dashboard_col">
						<div className="dashboard_item">
							<div className="dashboard_item_background" style={{ backgroundImage: "url(images/chart1.jpg)" }}></div>
						</div>
					</div>
					<div className="col-lg dashboard_col">
						<div className="dashboard_item">
							<div className="dashboard_item_background" style={{ backgroundImage: "url(images/chart1.jpg)" }}></div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Dashboard;