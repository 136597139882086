import React from 'react';
import { Container, Navbar, Nav, Offcanvas } from 'react-bootstrap';
import { CiGlobe, CiCalendar, CiClock2, CiCircleCheck, CiCircleRemove, CiFolderOn, CiDollar, CiLogout } from 'react-icons/ci';
import '../../../styles/Estatus.css';

function Header() {
  return (
    <>
      <div id="header" className="estatus-header">
        <Navbar key="false" expand="xxxl" className="mb-3" variant="dark">
          <Container>
            <Navbar.Brand>
              <div className="logo menu_logo"><a href="/">Tu boda en Cancún</a></div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="offcanvasNavbar-expand-xxxl" />
            <Navbar.Offcanvas id="offcanvasNavbar-expand-xxxl" aria-labelledby="offcanvasNavbarLabel-expand-xl" placement="start">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel-expand-xxxl">
                  Menú
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 ">
                  {/* Se modifica el Grid a xx-l */}
                  {/* <Nav.Link href="/admin" className="main_nav_item">Dashboard</Nav.Link>
                  <Nav.Link href="/admin/reservas" className="main_nav_item">Reservas</Nav.Link>
                  <Nav.Link href="/admin/pendientes" className="main_nav_item">Pendientes</Nav.Link>
                  <Nav.Link href="/admin/proceso" className="main_nav_item">En procesos</Nav.Link>
                  <Nav.Link href="/admin/cerrados" className="main_nav_item">Cerrados</Nav.Link>
                  <Nav.Link href="/admin/cancelados" className="main_nav_item">Cancelados</Nav.Link>
                  <Nav.Link href="/admin/pagos" className="main_nav_item">Pagos</Nav.Link>
                  <Nav.Link href="/" className="main_nav_item">Logout</Nav.Link> */}

                  <Nav.Link href="/admin" className="main_nav_item"><CiGlobe className="B-fa-icons" />Dashboard</Nav.Link>
                  <Nav.Link href="/admin/reservas" className="main_nav_item"><CiCalendar className="B-fa-icons" />Reservas</Nav.Link>
                  <Nav.Link href="/admin/pendientes" className="main_nav_item"><CiClock2 className="B-fa-icons" />Pendientes</Nav.Link>
                  <Nav.Link href="/admin/proceso" className="main_nav_item"><CiFolderOn className="B-fa-icons" />En procesos</Nav.Link>
                  <Nav.Link href="/admin/cerrados" className="main_nav_item"><CiCircleCheck className="B-fa-icons" />Cerrados</Nav.Link>
                  <Nav.Link href="/admin/cancelados" className="main_nav_item"><CiCircleRemove className="B-fa-icons" />Cancelados</Nav.Link>
                  <Nav.Link href="/admin/pagos" className="main_nav_item"><CiDollar className="B-fa-icons" />Pagos</Nav.Link>
                  <Nav.Link href="/" className="main_nav_item"><CiLogout className="B-fa-icons" />Logout</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div >
    </>
  );
}

export default Header;