import { ISocialList } from "../../interface/SocialList.interface";

interface Props {
    icon_list : ISocialList[];
}

function SocialList({icon_list} : Props) {
    return (
        <>
            {icon_list.map((item , index) => (
                <li key ={index.toString()} className={item.class_name}>
                    <a href={item.href}><i className={item.class_icon}></i></a>
                </li>
            ))}  
      </>
    );
}

export default SocialList;