import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';


interface Inputs {
    name            : string;
    cellphone       : number;
    email           : string;
    guest           : number;
    country         : string;
    type_of_package : string;
    date            : string;
    month           : number;
}

interface Errors {
    name_error            : string;
    cellphone_error       : string;
    email_error           : string;
    guest_error           : string;
    country_error         : string;
    type_of_package_error : string;
    date_error            : string;
    month_error           : string;
}

const useForm = (callback : any) => {
   const [inputs, setInputs] = useState<Inputs>
   ({
    name            : '',
    cellphone       : 0,
    email           : '',
    guest           : 0,
    country         : '',
    type_of_package : '',
    date            : '',
    month           : 0
  });
    
   const [errors, setErrors] = useState<Errors>
   ({
     name_error            : '',
     cellphone_error       : '',
     email_error           : '',
     guest_error           : '',
     country_error         : '',
     type_of_package_error : '',
     date_error            : '',
     month_error           : ''
   });

   const validate = (event : any , name : string , value : string) => {

        switch(name) {
            case 'name':
                if(value.length <= 4){
                    setErrors
                    ({
                        ...errors,
                        name_error :'El nombre debe tener un minimo de 5 letras'
                    });
                    return;
                }
                    setErrors({
                        ...errors,
                          name_error :''
                    });    
                
            break;
            case 'cellphone':

                if(value.length !== 10){
                    setErrors({
                        ...errors,
                          cellphone_error :'El número de telefono debe tener 10 digitos'
                    });
                    return;
                }
                setErrors({
                    ...errors,
                      cellphone_error :''
                });
             
            break;
            case 'email':
                if(
                    !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ){
                    setErrors({
                        ...errors,
                        email_error:'Enter a valid email address'
                    })
                    return;
                }
                setErrors({
                    ...errors,
                      email_error :''
                });
            break;
            case 'guest':
                if(Number(value) > 0){
                    setErrors({
                        ...errors,
                          guest_error :'Seleccione un número de invitados'
                    });    
                }else{
                    setErrors({
                        ...errors,
                          guest_error :''
                    });    
                }
            break;
            case 'country':
                if(value === 'seleccione'){
                    setErrors({
                        ...errors,
                          country_error :'Seleccione un estado'
                    });    
                }else{
                    setErrors({
                        ...errors,
                          country_error :''
                    });
                }
            break;
            case 'type_of_package':
                if(value.length > 0){
                    setErrors({
                        ...errors,
                          type_of_package_error :'Seleccione un paquete'
                    });    
                }
            break;
            case 'date':
                if(value.length > 0){
                    setErrors({
                        ...errors,
                          date_error :'Seleccione una fecha'
                    });    
                }
            break;
            case 'month':
                if(value.length > 0){
                    setErrors({
                        ...errors,
                          month_error :'Seleccione los meses de plazo'
                    });    
                }
            break;
        }

   };

   const handleChange = (event : any) => {
    //To stop default events    
    event.persist();

    let name = event.target.name;
    let val = event.target.value;

    validate(event,name,val);
    //Let's set these values in state
    setInputs({
        ...inputs,
        [name]:val,
    })

}


const handleSubmit = (event : any) => {
    if(event) event.preventDefault();

    if(
        inputs.name.length !==0 &&
        inputs.cellphone.toString().length == 10 &&
        inputs.guest > 0  
    ){
    
        toast.success("El correo fue enviado");
        event.target.reset();
    }else{
        toast.error("No se pudo enviar el correo :(")
        console.log(inputs);
    }
}


return {
    inputs,
    errors,
    handleChange,
    handleSubmit
}

}

export default useForm;