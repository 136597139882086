import { INavList } from "../../interface/NavList.interface";
import {Nav} from 'react-bootstrap';

interface Props {
    nav_list : INavList[];
}

function NavList({nav_list} : Props) {
    return(
        <>
        <Nav>
            {nav_list.map(item => (
            <Nav.Link key = {item.id} className={item.class_name} href={item.href}>{item.tag}</Nav.Link>
                ))}
        </Nav>
        </>
    );
    
}

export default NavList;