import '../../../styles/About.css';
import { useTranslation } from 'react-i18next';

function About() {
	const [t] = useTranslation("global");
	return (
		<>
			<div id="about" className="intro">
				<h2 className="intro_title text-center">{t("body.about_title")}</h2>
				<div className="container">
					<div className="row">
						<div className="col">

						</div>
					</div>
					<div className="row intro_items">
						<div className="col-lg intro_col">
							<div className="intro_item">
								<div className="intro_item_overlay"></div>
								<div className="intro_item_background" style={{ backgroundImage: "url(images/avion.jpg)" }}></div>
								<div className="intro_item_content d-flex flex-column align-items-center justify-content-center">
									<div className="intro_center text-center">
										<h1>{t("body.about_text_1")}</h1>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg intro_col">
							<div className="intro_item">
								<div className="intro_item_overlay"></div>
								<div className="intro_item_background" style={{ backgroundImage: "url(images/hotel.jpg)" }}></div>
								<div className="intro_item_content d-flex flex-column align-items-center justify-content-center">
									<div className="intro_center text-center">
										<h1>{t("body.about_text_2")}</h1>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row intro_items">
						<div className="col-lg intro_col">
							<div className="intro_item">
								<div className="intro_item_overlay"></div>
								<div className="intro_item_background" style={{ backgroundImage: "url(images/desayuno.jpg)" }}></div>
								<div className="intro_item_content d-flex flex-column align-items-center justify-content-center">
									<div className="intro_center text-center">
										<h1>{t("body.about_text_3")}</h1>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg intro_col">
							<div className="intro_item">
								<div className="intro_item_overlay"></div>
								<div className="intro_item_background" style={{ backgroundImage: "url(images/transporte.jpg)" }}></div>
								<div className="intro_item_content d-flex flex-column align-items-center justify-content-center">
									<div className="intro_center text-center">
										<h1>{t("body.about_text_4")}</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<div className="about_video">
								<iframe width="100%" height="550" src="https://www.youtube.com/embed/0syv798gP_g?start=1" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default About;