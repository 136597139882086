import { WeddingPackages } from "../../interface/WeddingPackages.interface";

interface Props {
    w_package : WeddingPackages[];
}

function WeddingPackageList({w_package} : Props) {
    return (
        <>
            <option></option>
            {w_package.map((item , index) => (
                <option key ={index.toString()} typeof={item.typeof} value = {item.name}>
                    {item.name}
                </option>
            ))}  
      </>
    );
}

export default WeddingPackageList;