import { States_USA } from "../../interface/States_USA.interface";

interface Props {
    states_usa : States_USA[];
}

function StatesList({states_usa} : Props) {
    return (
        <>
             <option key ='default' >
                    
             </option>
            {states_usa.map((item , index) => (
                <option key ={index.toString()} typeof={item.typeof}>
                    {item.name}
                </option>
            ))}  
      </>
    );
}

export default StatesList;