import React, { useState } from 'react';
import { Form, InputGroup, Row } from 'react-bootstrap';
import '../../../styles/Contact.css';
import { Toaster } from 'react-hot-toast';
import StatesList from '../../Tools/StatesList';
import WeddingPackageList from '../../Tools/PackageList';
import { WeddingPackages } from '../../../interface/WeddingPackages.interface';
import { States_USA } from '../../../interface/States_USA.interface';
import JSON_CONFIG_ES from '../../../tags/web_config_es.json';
import { useTranslation } from 'react-i18next';
import useForm from '../../../Hooks/HookForm';


const states_list_es: States_USA[] = JSON_CONFIG_ES.states_usa as States_USA[];
const wedding_packages_es: WeddingPackages[] = JSON_CONFIG_ES.package_wedding as WeddingPackages[];


function FormExample() {
  const [t] = useTranslation("global");
  const options: Array<string> = ['option1', 'option2', 'option3', 'option4', 'option5'];
  const state = {
    button: true,
  };

  const formLogin = () => {

    console.log("Callback function when form is submitted!");
    console.log("Form Values ", inputs);
  }

  const { handleChange, inputs, errors, handleSubmit } = useForm(formLogin);

  return (
    <>
      <h2 className="intro_title" style={{ textAlign: 'center' }}>Crea tu Boda</h2>
      <div id="contact" className="contact">
        <div className="container px-2">
          <div className="contact_form_container">
            <Form className="form-weadding" onSubmit={handleSubmit}>
              <Row className="mb-4">

                <Form.Group controlId="validationCustom01">
                  <Form.Label className="formlabel">{t("body.form_name")}</Form.Label>
                  <Form.Control className="form_str"
                    onChange={handleChange}
                    required
                    type="text"
                    name="name"
                    placeholder={t("body.name_placeholder") && ''} />
                  <Form.Control.Feedback>{t("body.form_message")}</Form.Control.Feedback>
                  {errors.name_error && <h6 style={{ color: 'red' }}>{errors.name_error}</h6>}
                </Form.Group>

                <Form.Group controlId="validationCustom02">
                  <Form.Label className="formlabel">{t("body.form_phone")}</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    required
                    type="tel"
                    name="cellphone"
                    pattern="[0-9]{10}"
                    aria-label='cellphone'
                    placeholder={t("body.phone_placeholder") && ''} />
                  <Form.Control.Feedback>{t("body.form_message")}</Form.Control.Feedback>
                  {errors.cellphone_error && <h6 style={{ color: 'red' }}>{errors.cellphone_error}</h6>}
                </Form.Group>

                <Form.Group controlId="validationCustomemail">
                  <Form.Label className="formlabel">{t("body.form_email")}</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      onChange={handleChange}
                      type="text"
                      placeholder={t("body.email_placeholder") && ''}
                      name="email"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                    <Form.Control.Feedback type="invalid">{t("body.form_email_alert")}</Form.Control.Feedback>
                  </InputGroup>
                  {errors.email_error && <h6 style={{ color: 'red' }}>{errors.email_error}</h6>}
                </Form.Group>
              </Row>

              <Row>
                <Form.Group >
                  <Form.Label className="formlabel">{t("body.form_credit")}</Form.Label>
                  <Form.Select
                    name="guest"
                    onChange={handleChange}
                    defaultValue="guest" required>
                    <option value=""></option>
                    <option value="1">  1 meses</option>
                    <option value="2">  2 meses</option>
                    <option value="3">  3 meses</option>
                    <option value="4">  4 meses</option>
                    <option value="5">  5 meses</option>
                    <option value="6">  6 meses</option>
                    <option value="7">  7 meses</option>
                    <option value="8">  8 meses</option>
                    <option value="9">  9 meses</option>
                    <option value="10">10 meses</option>
                    <option value="11">11 meses</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="validationstatesUSA">
                  <Form.Label className="formlabel">{t("body.form_state")}</Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    name='country'
                    placeholder={t("body.state_placeholder") && ''}
                    required
                  >
                    <StatesList states_usa={states_list_es} />
                  </Form.Select>
                  {errors.country_error && <h6 style={{ color: 'red' }}>{errors.country_error}</h6>}
                </Form.Group>

                <Form.Group controlId="validationpackage">
                  <Form.Label className="formlabel">{t("body.form_package")}</Form.Label>
                  <Form.Select
                    onChange={handleChange}
                    name='type_of_package'

                    required>
                    <WeddingPackageList w_package={wedding_packages_es} />
                  </Form.Select>
                </Form.Group>

              </Row>

              <Row>
                <Form.Group controlId="">
                  <Form.Label className="formlabel">{t("body.form_date")}</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    min=""
                    max="2022-12-20"
                    id='DateOne'
                    name='date'
                    type="date"
                    data-date-format="dd/mm/yyyy"
                    placeholder={t("body.date_placeholder") && ''}
                    required
                  />
                  <Form.Control.Feedback type="invalid">{t("body.form_date_alert")}</Form.Control.Feedback>
                </Form.Group>


                <Form.Group >
                  <Form.Label className="formlabel">{t("body.form_credit")}</Form.Label>
                  <Form.Select
                    name="month"
                    onChange={handleChange}
                    defaultValue="Choose your package" required>
                    <option value=""></option>
                    <option value="1">  1 meses</option>
                    <option value="2">  2 meses</option>
                    <option value="3">  3 meses</option>
                    <option value="4">  4 meses</option>
                    <option value="5">  5 meses</option>
                    <option value="6">  6 meses</option>
                    <option value="7">  7 meses</option>
                    <option value="8">  8 meses</option>
                    <option value="9">  9 meses</option>
                    <option value="10">10 meses</option>
                    <option value="11">11 meses</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className='py-4'>
                  <button className="form_submit_button" type="submit">Enviar</button>
                </Form.Group>
              </Row>
            </Form>
            <Toaster
              position="bottom-center"
              reverseOrder={false}
            />
            {/* <CurrentDate/> */}
          </div>
        </div>
      </div>

    </>

  );
}

export default FormExample;