import { Carousel, Row } from 'react-bootstrap';
import '../../../styles/Carousel.css';
import AppImage from '../../Tools/AppImage';
import { useTranslation } from 'react-i18next';

export interface Props {
  src1: string;
  src2: string;
  src3: string;
}

function IndividualIntervalsExample({ src1, src2, src3 }: Props) {

  const [t] = useTranslation("global");

  return (
    <>
      <div id="home">
        <Row>
          <Carousel >

            <Carousel.Item interval={1000}>
              <div className='bg-image' >
                <img
                  className="tam_imagen" src={src1}
                  alt="First slide"
                />
                <div className="carousel_contenido text-center text-align: center">
                  <Row>
                    <div className="carousel_contenido_inner ">
                      <h1>{t("body.title")}</h1>
                      <h1>{t("body.slogan")}</h1>
                      <div className="logo-hotel">
                        <a href="https://www.krystalhotels.com.mx/CANCUN"><AppImage src={"images/logo-krystal-2.png"} /></a>
                      </div>
                    </div>
                    <div className='logo-order'>
                      <AppImage src={"images/logo-order-express.png"} />
                    </div>
                  </Row>
                </div>
              </div>
              <Carousel.Caption>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={1000}>
              <div className='bg-image'>

                <img
                  className="tam_imagen"
                  src={src2}
                  alt="Second slide"
                />
                <div className="contenido_logo_slider_krystal text-center text-align: center">
                  <div className="contenido_logo_slider2_inner ">
                    <div className="hotel-slider_2">
                      <a href="https://www.krystalhotels.com.mx/CANCUN"><AppImage src={"images/logo-krystal-2.png"} /></a>

                    </div>
                  </div>
                </div>
              </div>
              <Carousel.Caption>
                <div className='legenda_slider2'>
                  <h1 className='secondslide'>¡Una Boda soñada en la Playa... con todos tus amigos!</h1>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <div className='bg-image'>
                <img
                  className="tam_imagen"
                  src={src3}
                  alt="Third slide"
                />
                <div className="contenido_logo_slider_krystal2 text-center text-align: center">
                  <div className="contenido_logo_slider3_inner ">
                    <div className="hotel-slider">
                      <a href="https://www.krystalhotels.com.mx/CANCUN"><AppImage src={"images/logo-krystal-2.png"} /></a>

                    </div>
                  </div>
                </div>
              </div>
              <Carousel.Caption>
                <div className='legenda_slider3'>
                  <h1 className='thirdslide'>¡En la Mejor Playa de Cancún!</h1>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

          </Carousel>
        </Row>
      </div>
    </>
  );
}

export default IndividualIntervalsExample;