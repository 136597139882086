import React from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import NavList from '../../Tools/NavList';
import { useTranslation } from 'react-i18next';
import AppImage from '../../Tools/AppImage';
import { Link } from 'react-router-dom';
import '../../../styles/Login.css';
import '../../../styles/header.css';

function Header() {
  const [t, i18n] = useTranslation('global');

  return (
    <>
      <div id="header" className="header">

        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Container>
            <Navbar.Brand href="#home">
              <div className="logo menu_logo"><a href=" ">{t('body.title')}</a></div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center text-center">
              <Nav>
                <NavList nav_list={t('header.nav_list', { returnObjects: true })} />
                <div className="main_lang">
                  <button className="main_button" onClick={() => i18n.changeLanguage('en')}>
                    <AppImage src={'images/estados-unidos.png'}></AppImage>
                  </button>
                  <button className="main_button" onClick={() => i18n.changeLanguage('es')}>
                    <AppImage src={'images/mexico.png'}></AppImage>
                  </button>
                </div>
                <NavDropdown title="Login" id="collasible-nav-dropdown">
                  <form className="form-container m-2">
                    <div className="form-outline mb-4">
                      <label className="form-label">Email address</label>
                      <input type="email" id="email" className="form-control" />
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label">Password</label>
                      <input type="password" id="password" className="form-control" />
                    </div>
                    <Link to="/admin" type="button" className="header_submit_button btn btn-dark btn-sm">Sign in</Link>
                  </form>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Header;
