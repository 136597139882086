import '../styles/body-general.css';
import '../styles/responsive.css';
import Header from '../components/BackOffice/Header/Header';
import Dashboard from '../components/BackOffice/Body/Dashboard';

function BackOffice() {
  return (
    <>
      <div className="super_container">
        <Header />
        <Dashboard />
      </div>
    </>
  );
}

export default BackOffice;
