import { Carousel, Row } from 'react-bootstrap';
import AppImage from '../../Tools/AppImage';
import '../../../styles/Carousel.css';

export interface Props {
  src1: string;
  src2: string;
  src3: string;
}

function CarouselFadeExample({ src1, src2, src3 }: Props) {
  return (
    <>
      <div id="garantía">
        <Row>
          <h4 className="intro_title" style={{ textAlign: 'center' }}>Contamos con la garantía de...</h4>
        </Row>
        <Carousel fade>
          <Carousel.Item>
            <div className="bg-image">
              <img className="tam_imagen" src={src1} alt="First slide" />
              <div className="posicion_logo_order text-center text-align: center">
                <Row>
                  <div className="posicion_logo_order_inner ">
                    <div className='logo-order-slider2'>
                      <AppImage src={"images/logo-order-express.png"} />
                    </div>
                  </div>
                </Row>
              </div>
            </div>
            <Carousel.Caption className='legend-order' >
              <h1>“Nos confías el Dinero de tu Familia desde hace 25 Años…”</h1>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <div className="bg-image">
              <img className="tam_imagen" src={src2} alt="Second slide" />
            </div>
            <Carousel.Caption className='texto-krystal' >
              <div className='legend-krystal-slider2'>
                <div className='logo-hotel-slider2'>
                  <AppImage src={"images/logo-krystal-2.png"} />
                </div>
              </div>
              <h2 >El Hotel Krystal Cancún Zona Hotelera es el mejor aliado para pasarla como nunca durante las vacaciones familiares, con amigos o en pareja. Sus completos servicios, que incluyen desde gastronomía a club de Playa, y su privilegiada ubicación junto al Mar Caribe, hacen de este hotel en Punta Cancún (Corazón de la Zona Hotelera), un auténtico refugio de relajación y placer a medida de cualquier gusto o necesidad.</h2>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
}

export default CarouselFadeExample;