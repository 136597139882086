// react
import React from 'react';
// application
import { imageOnErrorHandler} from '../../services/utils';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {}

const AppImage = React.forwardRef((props: Props, ref: React.Ref<HTMLImageElement>) => {
    const { src, ...otherProps } = props;
    return <img alt="" {...otherProps} src={props.src} ref={ref} onError={imageOnErrorHandler} />});

export default AppImage;