import { ILocaction } from '../interface/Locations.interface';

const dataListLocations: ILocaction[] = [
    {
        name: 'Playa',
        background: 'images/Playa/Playa1.jpg',
        images: ['images/Playa/Playa2.png', 'images/Playa/Playa4.png', 'images/Playa/Playa3.png', 'images/Playa/Playa5.png'],
        descriptions: ['Ceremonia 200 invitados','Cóctel 400 invitados', 'Banquete 250 invitados']
    },
    {
        name: '7 Columnas',
        background: 'images/7-Columnas/Columnas1.png',
        images: ['images/7-Columnas/Columnas3.png', 'images/7-Columnas/Columnas4.png', 'images/7-Columnas/Columnas1.png', 'images/7-Columnas/Columnas5.png'],
        descriptions: ['Ceremonia 120 invitados','Cóctel 100 invitados', 'Banquete 60 invitados']
    },
    {
        name: 'Salón',
        background: 'images/Salon/Salon4.webp',
        images: ['images/Salon/Salon2.png', 'images/Salon/Salon3.webp', 'images/Salon/Salon4.webp', 'images/Salon/Salon5.png'],
        descriptions: ['Ceremonia 100 invitados','Cóctel 150 invitados']
    },
    {
        name: 'Terraza Fisheria',
        background: 'images/Terraza-Fisheria/Terraza4.webp',
        images: ['images/Terraza-Fisheria/Terraza1.png', 'images/Terraza-Fisheria/Terraza2.png', 'images/Terraza-Fisheria/Terraza3.png', 'images/Terraza-Fisheria/Terraza5.jpeg'],
        descriptions: ['Ceremonia 100 invitados','Cóctel 150 invitados']
    }
];

export default dataListLocations;