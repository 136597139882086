import '../styles/body-general.css';
import '../styles/responsive.css';
import '../plugins/font-awesome-4.7.0/css/font-awesome.min.css';
import Header from '../components/Landingpage/Header/Header';
import Footer from '../components/Landingpage/Footer/Footer';
import About from '../components/Landingpage/Body/About';
import Form from '../components/Landingpage/Body/Form';
import Carousel1 from '../components/Landingpage/Body/Carousel2';
import Carousel2 from '../components/Landingpage/Body/Carousel';
import Process from '../components/Landingpage/Body/Process';
import Locations from '../components/Landingpage/Body/Locations';

function Landingpage() {
  return (
    <>
      <div className="super_container">
        <Header />
        <Carousel1
          src1='images/bodas_home.jpg'
          src2='images/amigos_boda.jpg'
          src3='images/hotel_krystal_cancun.jpg' />
        <About />
        <Locations />
        <Carousel2
          src1='images/pareja_bodas.jpg'
          src2='images/hotel_krystal_cancun.jpg'
          src3='images/illinois.jpg' />
        <Process />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Landingpage;
