import '../../../styles/Process.css';

function About (){
return(
    <>
	    <div id="proceso" className="process">
			<div className="container">
				<div className="row">
					 <div className="col">
						<h2 className="process_title text-center">¿Qué debes hacer?</h2>
					</div>
			    </div>

				<div className="row process_items">
				    <div className="col-lg process_col">
						<div className="process_item">
							<div className="process_item_overlay"></div>
							<div className="process_item_background" style={{backgroundImage:"url(images/organizer.jpg)"}}></div>
							<div className="process_item_content d-flex flex-column align-items-center">
							    <div className="process_center">
								<div className="process-number"><h1>1</h1></div>
									<h1>Define una fecha</h1>
									<ul>
										<li>Puedes organizar Tu Boda hasta con 1 Año de anticipación.</li>
										<li>Elije con un margen de 2 semanas para buscar la mejor opción.</li>
										<li>Podrán dividir los Pagos del viaje por la cantidad de meses que falten para la Boda.</li>
									</ul>
							    </div>
						    </div>
					    </div>
					</div>
				    <div className="col-lg process_col">
						<div className="process_item">
							<div className="process_item_overlay"></div>
							<div className="process_item_background" style={{backgroundImage:"url(images/friends.jpg)"}}></div>
							<div className="process_item_content d-flex flex-column align-items-center">
							    <div className="process_center">
									<div className="process-number"><h1>2</h1></div>
									<h1>Consulta con tus amigos y familia</h1>
									<ul>
										<li>Comunícales tus intenciones de tu Boda asociadas a unas Vacaciones en Cancún.</li>
										<li>Explícales que el viaje incluye: 5 días y 4 noches.</li>
										<li>Todo será posible por una accesible cuota mensual.</li>
									</ul>
							    </div>
						    </div>
					    </div>
					</div>
					<div className="col-lg process_col">
						<div className="process_item">
							<div className="process_item_overlay"></div>
							<div className="process_item_background" style={{backgroundImage:"url(images/01-contact-serv.jpg)"}}></div>
							<div className="process_item_content d-flex flex-column align-items-center">
							    <div className="process_center">
								<div className="process-number"><h1>3</h1></div>
									<h1>Escoge una locación</h1>
									<p>Contamos con 4 maravillas localizaciones:</p>
									<ul>
										<li>Playa</li>
										<li>7 Columnas</li>
										<li>Salón</li>
										<li>Terraza Fisheria</li>
									</ul>
							    </div>
						    </div>
					    </div>
					</div>
				</div>
		    </div>
	    </div>
    </>
);
}

export default About;