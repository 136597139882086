import '../../../styles/Footer.css';
import TagList from '../../Tools/TagList';
import SocialList from '../../Tools/SocialList';
import { ISocialList } from '../../../interface/SocialList.interface';
import JSON_CONFIG_EN from '../../../tags/web_config_es.json';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import Iframe from 'react-iframe';
import { useTranslation } from 'react-i18next';
import AppImage from '../../Tools/AppImage';

const list_icon_en: ISocialList[] = JSON_CONFIG_EN.footer_icon_list as ISocialList[];

function Footer() {
  const [t] = useTranslation("global");

  return (
    <>
      <footer id="" className="footer">
        <div className="container">
          <div className='footer-logo'>
            <AppImage src={"images/logo-order-express.png"} /></div>
          <div className="row">
            {/* <!-- Footer Column --> */}
            <div className="col-lg-3 footer_column">
              <div className="footer_col">
                <div className="footer_title">{t("body.title")}</div>
                <p className="footer_about_text">{t("footer.description")}</p>
                <ul className="footer_social_list">
                  <SocialList icon_list={list_icon_en} />
                </ul>

              </div>
            </div>

            {/* <!-- Footer Column --> */}
            <div className="col-lg-3 footer_column">
              <div className="footer_col">
                <div className="footer_title">{t("footer.title_2")}</div>
                <div className="footer_content footer_blog">
                  {/* <!-- Footer blog item --> */}
                  <div className="footer_blog_item clearfix">
                    <div className="footer_blog_image"><img src="images/trend_5.png" alt="https://unsplash.com/@avidenov" /></div>
                    <div className="footer_blog_content">
                      <div className="footer_blog_title"><a href="blog.html">{t("footer.blog_title_1")}</a></div>
                      <div className="footer_blog_date">$</div>
                    </div>
                  </div>
                  {/* <!-- Footer blog item --> */}
                  <div className="footer_blog_item clearfix">
                    <div className="footer_blog_image"><img src="images/trend_5.png" alt="https://unsplash.com/@deannaritchie" /></div>
                    <div className="footer_blog_content">
                      <div className="footer_blog_title"><a href="blog.html">{t("footer.blog_title_2")}</a></div>
                      <div className="footer_blog_date">$</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Footer Column --> */}
            <div className="col-lg-3 footer_column">
              <div className="footer_col">
                <div className="footer_title">{t("footer.title_3")}</div>
                <div className="footer_content footer_tags">
                  <div className="tag_list">
                    <ul className="tags_list clearfix">
                      <TagList tag_list={t("footer.tag_list", { returnObjects: true })} />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Footer Column --> */}
            <div className="col-lg-3 footer_column">
              <div className="footer_col">
                <div className="footer_title">{t("footer.title_4")}</div>
                <div className="footer_content footer_contact">
                  <ul className="contact_info_list">
                    <li className="contact_info_item d-flex flex-row">
                      <div><div className="fa-icons"><FaMapMarkerAlt /></div></div>
                      <div className="contact_info_text">{t("footer.address")}</div>
                    </li>
                    <li className="contact_info_item d-flex flex-row">
                      <div><div className="fa-icons"><FaPhoneAlt /></div></div>
                      <div className="contact_info_text">{t("footer.phone_number")}</div>
                    </li>
                    <li className="contact_info_item d-flex flex-row">
                      <div><div className="fa-icons"><FaEnvelope /></div></div>
                      <div className="contact_info_text"><a href="mailto:contactme@gmail.com?Subject=Hello" target="_top">{t("footer.email")}</a></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="ubicacion" className="ubicacion">
          <div className="footer_title" style={{ textAlign: 'center', paddingTop: "2%" }}>{t("footer.title_5")}</div>
          <div className='ubicacion-container'>
            <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.457314137893!2d-86.74760445003092!3d21.13419138947877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2b9c347ef01f%3A0x84d65c823549a552!2sHotel%20Krystal%20Canc%C3%BAn!5e0!3m2!1ses-419!2smx!4v1667486711762!5m2!1ses-419!2smx" width="100%" height="150" styles={{ border: "0" }} allowFullScreen loading="lazy" referrerpolicy="no-referrer-when-downgrade" url={''} />
          </div>

        </div>
      </footer>
      {/* <!-- Copyright --> */}
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 order-lg-1 order-2  ">
              <div className="copyright_content d-flex flex-row align-items-center">
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;