import { ITagList } from "../../interface/TagList.interface"; 

interface Props {
    tag_list : ITagList[]
}

function Tags({ tag_list } : Props) {
    return (
        <>
        { tag_list.map((item, index) =>(
            <li key ={index.toString()} className={item.class_name}><a href={item.href}>{item.tag}</a></li>
        ))}
        </>
    );
}

export default Tags;