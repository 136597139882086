import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landingpage from './pages/Landingpage';
import BackOffice from './pages/BackOffice';
import Dashboard from './components/BackOffice/Body/Dashboard';
import Reservas from './components/BackOffice/Body/Reservas';
import Pendientes from './components/BackOffice/Body/Pendientes';
import Proceso from './components/BackOffice/Body/Proceso';
import Cerrados from './components/BackOffice/Body/Cerrados';
import Cancelados from './components/BackOffice/Body/Cancelados';
import Pagos from './components/BackOffice/Body/Pagos';

function App(): JSX.Element {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/admin" element={<BackOffice />} />

          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/reservas" element={<Reservas />} />
          <Route path="/admin/pendientes" element={<Pendientes />} />
          <Route path="/admin/proceso" element={<Proceso />} />
          <Route path="/admin/cerrados" element={<Cerrados />} />
          <Route path="/admin/cancelados" element={<Cancelados />} />
          <Route path="/admin/pagos" element={<Pagos />} />

          <Route path="/" element={<Landingpage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
