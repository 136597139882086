import React, { useState } from 'react';
import '../../../styles/Locations.css';
import { ILocaction } from '../../../interface/Locations.interface';
import dataListLocations from '../../../data/list-locations';
import { Carousel } from 'react-bootstrap';

function Locations() {

    const [showModal, setShowModal] = useState(false);
    const locations: ILocaction[] = dataListLocations;
    const [selectedLocation, setSelectedLocation] = useState<ILocaction>({} as ILocaction)

    const handleShowModal = (location: ILocaction) => {
        setSelectedLocation(location);
        setShowModal(true);
    };

    const modalTable = (
        <React.Fragment>
            <div className="modal_table">
                <div className="modal_content">
                    <div className="scroll_div_table">
                        <div>
                            <h2 className="modal_title text-center">{selectedLocation.name}</h2>
                        </div>
                        <div className="modal_Carousel">
                            <Carousel className="size_slide">
                                {selectedLocation?.images?.map((image) => (
                                    <Carousel.Item interval={1000}>
                                        <img className="modal_imagen" src={image} alt=" " />
                                    </Carousel.Item>
                                )
                                )}
                            </Carousel>
                        </div>
                        {selectedLocation?.descriptions?.map((description) => (
                            <div className="modal_text justify-content-center text-center">{description}</div>
                        )
                        )}
                        <div className="modal_footer">
                            <button className="btn_location" type="button" onClick={() => setShowModal(!showModal)}>
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );

    return (
        <>
            <div id="about" className="intro">
                <h2 className="intro_title text-center">Locaciones</h2>
                <div className="container">
                    <div className="row intro_items">
                        {locations.map((location, index) => {
                            if (index <= 1) {
                                return (
                                    <div className="col-lg intro_col">
                                        <div className="intro_item" onClick={() => handleShowModal(location)}>
                                            <div className="intro_item_overlay"></div>
                                            <div className="intro_item_background" style={{ backgroundImage: `url(${location.background})` }}></div>
                                            <div className="intro_item_content d-flex flex-column align-items-center justify-content-center">
                                                <div className="intro_center text-center">
                                                    <h1>{location.name}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else return null;
                        })}

                    </div>
                    <div className="row intro_items">
                        {locations.map((location, index) => {
                            if (index >= 2) {
                                return (
                                    <div className="col-lg intro_col">
                                        <div className="intro_item" onClick={() => handleShowModal(location)}>
                                            <div className="intro_item_overlay"></div>
                                            <div className="intro_item_background" style={{ backgroundImage: `url(${location.background})` }}></div>
                                            <div className="intro_item_content d-flex flex-column align-items-center justify-content-center">
                                                <div className="intro_center text-center">
                                                    <h1>{location.name}</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else return null;
                        })}
                    </div>
                </div>
                {showModal && modalTable}
            </div>
        </>
    );
}

export default Locations;