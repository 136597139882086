import React from 'react';
import { Container, Table } from 'react-bootstrap';
import Header from '../Header/Header';

function Cerrados() {
    return (
        <div id="cerrados" className="cerrados">
            <Header />
            <Container>
                <h1>Cerrados</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                    sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th>Telefono</th>
                            <th>Estado de origen</th>
                            <th>Paquete</th>
                            <th>Fecha de la boda</th>
                            <th>Numero de invitados</th>
                            <th>Plazos de pago</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Mark Smith</td>
                            <td>mark@gmail.com</td>
                            <td>+52 5555555555</td>
                            <td>Los Angeles</td>
                            <td>Boda</td>
                            <td>+50</td>
                            <td>12/12/2020</td>
                            <td>3 meses</td>
                        </tr>
                    </tbody>
                </Table>
            </Container>
        </div>
    );
}

export default Cerrados;